var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"status","rules":_vm.requiredField ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status do Faturamento","label-for":"status"}},[_c('v-select',{class:errors.length > 0 ? 'error-select' : '',attrs:{"id":"status","filter":_vm.fuseSearch,"label":"name","options":_vm.dataOptions,"placeholder":'Status do Faturamento',"disabled":_vm.readonly,"clearable":!_vm.readonly},on:{"open":function($event){return _vm.onCreate()}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('b-badge',{staticClass:"text-capitalize",style:({'background-color': color, 'min-width':'110px'}),attrs:{"pill":""}},[_vm._v(" "+_vm._s(_vm._f("truncate")(name,18, '...'))+" ")])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('b-badge',{staticClass:"text-capitalize",style:({'background-color': color, 'min-width':'110px'}),attrs:{"pill":""}},[_vm._v(" "+_vm._s(_vm._f("truncate")(name,18, '...'))+" ")])]}},{key:"no-options",fn:function(){return [_vm._v(" Não há opções de status. ")]},proxy:true}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }