<template>
  <div>
    <b-row
      class=""
    >
      <b-col>
        <b-card
          class="mb-2 mx-2"
          no-body
        >
          <b-card-title
            class="clsCardTitleViewMsg d-flex justify-content-between pt-50 px-1 pb-50 mb-0"
          >
            <div
              class="d-flex align-items-center"
            >
              <b-avatar
                v-if="ownerImage"
                :src="ownerImage"
                size="2rem"
              />
              <b-avatar
                v-else
                size="2rem"
              />
              <span class="d-inline ml-50">{{ message.author_set.first_name }}</span>
            </div>
            <div
              v-if="isMessageFromAuthor()"
              class="d-flex align-items-center"
            >
              <b-button
                id="idMessagerDelBtn"
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="sm"
                @click="showConfirmOrCancelDelete()"
              >
                <svg-icon
                  type="mdi"
                  :path="mdiTrashCanOutline"
                  size="18"
                />
              </b-button>
            </div>
          </b-card-title>
          <b-card-body
            class="py-1"
          >
            <b-row>
              <b-col>
                <p>{{ message.message }}</p>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer
            class="py-50"
          >
            <b-row>
              <b-col
                class="clsCardMessageFooterView"
                cols="12"
                md="4"
              >
                <message-visualization
                  :message="message"
                />
              </b-col>
              <b-col
                class="clsCardMessageFooterTime"
                cols="12"
                md="8"
              >
                <span>{{ messageTime }}</span>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BCardFooter,
  BAvatar,
  BButton,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiTrashCanOutline,
  mdiEyeOutline,
} from '@mdi/js'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MessageVisualization from '@/components/messager/MessageVisualization.vue'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BAvatar,
    BButton,
    MessageVisualization,
    SvgIcon,
  },
  props: {
    module: {
      type: [String],
      required: true,
    },
    thread: {
      type: [String, Number],
      required: true,
    },
    message: {
      type: [Object],
      required: true,
    },
  },
  data() {
    const ownerImage = null
    const profile = syslic.authentication.getProfile()
    return {
      ownerImage,
      profile,
    }
  },
  computed: {
    messageTime: {
      get: function convertTime() {
        return moment(this.message.created_at).format('DD/MM/YYYY HH:mm:ss')
      },
    },
  },
  watch: {
    message: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    remove() {
      syslic
        .messager
        .deleteMessage(this.message.id)
        .then(() => {
          this.$emit('remove', this.message)
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Menssagem removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover menssagem.',
              text: 'Não foi possível remover a menssagem ao servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete() {
      this.$bvModal
        .msgBoxConfirm('Deseja realmente excluir essa atividade? Essa ação não poderá ser desfeita.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.remove()
          }
        })
    },
    fetchData() {
      if (this.message.author_set) {
        syslic.user.getUserImage(this.message.author_set.get_uuid)
          .then(response => {
            this.ownerImage = response
          })
          .catch(() => {
            this.ownerImage = null
          })
      }
    },
    isMessageFromAuthor() {
      return this.message.author_set.id === this.profile.id
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrashCanOutline,
      mdiEyeOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.clsCardTitleViewMsg {
  background-color: #e2edf3;
  color: #464646;
  font-size: 1rem;
  font-weight: 400;
}

.clsCardMessageFooterView {
  text-align: left;
}
.clsCardMessageFooterTime {
  font-size: 0.85rem;
  font-weight: 400;
  text-align: right;
}
</style>
