<template>
  <div>
    <div id="idCommunicatorBtn">
      <b-button
        :class="getIsViewed(messages) ? '' : 'messageNotViewed'"
        variant="outline-primary"
        size="sm"
        @click="open()"
      >
        <svg-icon
          type="mdi"
          :path="mdiCommentProcessingOutline"
        />
      </b-button>
    </div>
    <b-sidebar
      right
      shadow
      :visible="isOpen"
      backdrop-variant="dark"
      backdrop
      width="35rem"
      @hidden="close()"
    >
      <b-row
        v-if="header !== ''"
        id="idCommunicatorHeader"
        class="ml-50 mb-2"
      >
        <b-col>
          <span>{{ header }}</span>
        </b-col>
      </b-row>
      <b-row
        id="idCommunicatorActivityHeader"
        class="ml-50"
      >
        <b-col>
          <div
            class="d-flex align-items-center"
          >
            <svg-icon
              type="mdi"
              :path="mdiCalendarCheckOutline"
            />
            <span class="ml-50 d-inline">Atividades</span>
          </div>
        </b-col>
      </b-row>
      <hr class="mt-50">
      <messager-edit-message
        ref="refEditMsg"
        :module="module"
        :thread="thread"
        :users="users"
        @add="notifyAddMessage"
      />
      <hr>
      <div>
        <b-row
          v-for="(message, index) in messages"
          :key="index"
        >
          <b-col>
            <messager-view-message
              :module="module"
              :thread="thread"
              :message="message"
              @remove="notifyRemoveMessage"
            />
          </b-col>
        </b-row>
      </div>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSidebar,
} from 'bootstrap-vue'

import {
  mdiCommentProcessingOutline,
  mdiCalendarCheckOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SyncLoad from '@/components/utils/syncLoad'
import MessagerEditMessage from '@/components/messager/MessagerEditMessage.vue'
import MessagerViewMessage from '@/components/messager/MessagerViewMessage.vue'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    MessagerEditMessage,
    MessagerViewMessage,
    SvgIcon,
  },
  props: {
    module: {
      type: [String],
      required: true,
    },
    thread: {
      type: [String, Number],
      required: true,
    },
    header: {
      type: [String],
      required: false,
      default: '',
    },
  },
  data() {
    const syncLoad = new SyncLoad()
    const profile = syslic.authentication.getProfile()
    syncLoad.addTrigger('users')
    syncLoad.addTrigger('messages')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      messages: [],
      isOpen: false,
      users: [],
      syncLoad,
      profile,
    }
  },
  created() {
    this.loadUsers()
    this.loadMessages()
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
      this.$refs.refEditMsg.clear()
    },
    notifyAddMessage() {
      this.loadMessages()
    },
    notifyRemoveMessage() {
      this.loadMessages()
    },
    getIsViewed(messages) {
      const profileId = this.profile.id
      let viewedCount = 0
      let authorMessageCount = 0

      messages.forEach(message => {
        if (message.author === profileId) {
          authorMessageCount += 1
        }

        if (message.views_by_profile.some(viewer => viewer.id === profileId)) {
          viewedCount += 1
        }
      })

      return authorMessageCount + viewedCount === messages.length
    },
    loadMessages() {
      const params = {
        ordering: '-created_at',
        module: this.module,
        thread: this.thread,
      }

      syslic
        .messager
        .fetchMessages(params)
        .then(response => {
          this.messages = response.data.results
          this.syncLoad.ready('messages')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar menssagens.',
              text: 'Não foi possível carregar as menssagens ao servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('messages')
        })
    },
    loadUsers() {
      syslic
        .user
        .fetchAuthors()
        .then(response => {
          const userList = []
          response.data.results.forEach(obj => {
            if (obj.get_full_name !== ' ') {
              userList.push({
                value: obj.id,
                label: obj.get_full_name,
              })
            }
          })

          this.users = userList
          this.syncLoad.ready('users')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$emit('users', true)
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiCommentProcessingOutline,
      mdiCalendarCheckOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idCommunicatorBtn {
  position: fixed;
  right: 0px;
  top: 7rem;
}

#idCommunicatorBtn .btn {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#idCommunicatorBtn .btn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#idCommunicatorHeader {
  font-size: 1.25rem !important;
  text-transform: uppercase;
  color: #6E6B7B;
  font-weight: 500;
}

#idCommunicatorActivityHeader {
  color: $primary
}

.messageNotViewed {
  background-color: $success_2;
  color: $white;
}
</style>
