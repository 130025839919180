<template>
  <div>
    <b-row
      class="px-2"
    >
      <b-col>
        <Mentionable
          :keys="['@']"
          :items="users"
          @apply="bindUser"
        >
          <b-form-textarea
            id="idCommunicatorMsg"
            v-model="newMessage"
            trim
            placeholder="Nova atividade..."
          />

          <template #no-result>
            <div class="dim">
              Não foram encontrados usuários com este nome.
            </div>
          </template>

          <template #item-@="{ item }">
            <span>
              {{ item.label }}
            </span>
          </template>

        </Mentionable>
      </b-col>
    </b-row>
    <b-row
      v-if="newMessage !== ''"
      class="px-2 mt-1"
    >
      <b-col
        class="d-flex justify-content-end"
      >
        <b-button
          id="idCommunicatorMsgSave"
          variant="primary"
          class="mr-0 mr-sm-1"
          @click="addMessage()"
        >
          Salvar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Mentionable from '@/components/mention/Mentionable.vue'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    Mentionable,
    BFormTextarea,
  },
  props: {
    module: {
      type: [String],
      required: true,
    },
    thread: {
      type: [String, Number],
      required: true,
    },
    users: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      newMessage: '',
      mentions: [],
      messages: '',
    }
  },
  methods: {
    clear() {
      this.newMessage = ''
    },
    bindUser(item, value) {
      const mention = {
        id: item.value,
        text: value,
      }

      this.mentions.push(mention)
    },
    addMessage() {
      const profile = syslic.authentication.getProfile()
      const mentions = []

      this.mentions.forEach(item => {
        if ((this.newMessage.includes(item.text))) {
          mentions.push(item.id)
        }
      })

      const message = {
        author: profile.id,
        module: this.module,
        thread: this.thread,
        message: this.newMessage,
        mentions,
      }

      syslic
        .messager
        .addMessage(message)
        .then(response => {
          this.newMessage = ''
          this.mentions = []
          this.$emit('add', response.data)
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Menssagem adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar menssagem.',
              text: 'Não foi possível adicionar a menssagem ao servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss" scoped>

#idCommunicatorMsg {
  min-height: 8rem;
}

</style>
