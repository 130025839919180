import log from 'loglevel'

class SyncLoad {
  constructor() {
    log.debug('[DEBUG] SyncLoad: initialize')
    this.onReadyCallback = () => {}
    this.triggerList = []
    this.isReady = false
    this.poll()
  }

  onReady(callback) {
    log.debug('[DEBUG] SyncLoad: register callback')
    this.onReadyCallback = callback
  }

  addTrigger(trigger) {
    log.debug(`[DEBUG] SyncLoad: Add tringger ${trigger}`)
    this.triggerList[trigger] = false
  }

  release() {
    let isReadyNew = true
    Object.keys(this.triggerList).forEach(key => {
      if (!this.triggerList[key]) {
        isReadyNew = false
      }
    })

    if (isReadyNew) {
      log.debug('[DEBUG] SyncLoad: all triggers are ready')
      this.isReady = true
      this.onReadyCallback()
    }
  }

  poll() {
    this.release()

    if (!this.isReady) {
      setTimeout(() => {
        log.debug('[DEBUG] SyncLoad Poll')
        this.poll()
      }, 5000)
    }
  }

  ready(trigger) {
    if (trigger in this.triggerList) {
      log.debug(`[DEBUG] SyncLoad: ${trigger} is ready`)
      this.triggerList[trigger] = true
      this.release()
    }
  }
}

export default SyncLoad
