/* eslint import/no-extraneous-dependencies: ["off"] */
import Swal from 'sweetalert2/dist/sweetalert2'

class ChangeDetector {
  constructor() {
    this.changes = false
    this.lastRefs = {}
    this.scope = null
    this.keys = []
  }

  bind(scope, objectKeys) {
    this.scope = scope

    objectKeys.forEach(key => {
      this.keys.push(key)
      this.lastRefs[key] = JSON.parse(JSON.stringify(this.scope[key]))
    })
  }

  hasChanges() {
    const changes = this.keys.some(
      key => JSON.stringify(this.scope[key]) !== JSON.stringify(this.lastRefs[key]),
    )

    if (changes) {
      Swal.fire({
        showConfirmButton: false,
        timer: 3000,
        icon: 'warning',
        title: 'Atenção!',
        text: 'Você tem mudanças não aplicadas! Salve os dados antes de realizar a operação.',
      })
    }

    return changes
  }

  reset() {
    this.keys.forEach(key => {
      this.lastRefs[key] = JSON.parse(JSON.stringify(this.scope[key]))
    })
  }
}

export default ChangeDetector
