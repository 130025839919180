<template>
  <div>
    <b-card no-body>
      <b-card-title class="m-0">
        <div class="invoicingtHeader">
          <b-row>
            <b-col
              class="px-3 py-2"
            >
              <div>
                <span
                  class="d-inline invoicingInfoTitle"
                >
                  {{ organName }}
                </span>
                <b-button
                  id="btn-link-invoicing-info"
                  type="button"
                  variant="outline-info"
                  class="mb-1 mb-sm-0 mr-0 p-0"
                  @click="goToBidding()"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiLaunch"
                    size="18"
                  />
                </b-button>
              </div>
              <div>
                <b-row class="mt-50">
                  <b-col class="d-flex align-items-center">
                    <span class="mr-50 invoicingInfoSubtitleLabel">Nº Pregão</span>
                    <span
                      v-if="invoicing.commitment_set.trade_number"
                      class="mr-2 invoicingInfoSubtitleContent"
                    >
                      {{ invoicing.commitment_set.trade_number }}
                    </span>
                    <span
                      v-else
                      class="mr-2 invoicingInfoSubtitleContent"
                    >
                      ...
                    </span>
                    <div>
                      <span class="mr-50 invoicingInfoSubtitleLabel">CNPJ</span>
                      <span
                        v-show="invoicing.commitment_set.client_set.cnpj"
                        class="invoicingInfoSubtitleContent"
                      >
                        {{ invoicing.commitment_set.client_set.cnpj | VMask('##.###.###/####-##') }}
                      </span>
                      <span
                        v-show="!invoicing.commitment_set.client_set.cnpj"
                        class="invoicingInfoSubtitleContent"
                      >
                        ...
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                class="btn-icon status-btn right-rounded"
                :class="situationButtonClass"
                :disabled="!canEdit || (isInAudit && !haveAuditPermission) || isDeclined"
                variant="white"
                @click="checkChangeDetections()"
              >
                <svg-icon
                  type="mdi"
                  size="36"
                  class="statusSvg"
                  :style="{ 'color': situationSvgColor }"
                  :path="situationSvgPath"
                />
                <span
                  class="statusText"
                >
                  {{ actionText }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-title>
      <b-row
        class="p-2"
      >
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            #default="validationContext"
            name="delivery_number"
            :rules="{
              required: true,
              regex:/^[a-zA-Z0-9\-/]+$/
            }"
          >
            <b-form-group
              label="Nº da Entrega"
              label-for="delivery_number"
            >
              <b-form-input
                id="delivery_number"
                v-model="invoicing.delivery"
                :state="readonly ? null : getValidationState(validationContext)"
                name="delivery_number"
                :placeholder="readonly ? '' : 'Nº da Entrega'"
                :readonly="true"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="pr-50"
        >
          <company-select
            id="company"
            v-model="invoicing.delivery_set.company_set"
            :readonly="true"
            :required-field="true"
            :truncate-field="30"
            @ready="syncLoad.ready('company')"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            #default="validationContext"
            name="commitment_number"
            :rules="{
              required: true,
              regex:/^[a-zA-Z0-9\-/]+$/
            }"
          >
            <b-form-group
              label="Nº do Empenho"
              label-for="commitment_number"
            >
              <b-form-input
                id="commitment_number"
                v-model="invoicing.commitment_set.number"
                :state="readonly ? null : getValidationState(validationContext)"
                name="commitment_number"
                :placeholder="readonly ? '' : 'Nº do Empenho'"
                :readonly="true"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="invoicing_delivery_date"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Data da Entrega"
              label-for="invoicing_delivery_date"
              :label-class="'formLabel'"
            >
              <b-form-datepicker
                id="id_invoicing_delivery_date"
                v-model="invoicing.delivery_set.invoicing_delivery_date"
                :required="false"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                :disabled="true"
                selected-variant="primary"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="pl-50"
        >
          <invoicing-status-select
            id="idOrderStatus"
            v-model="invoicing.status_set"
            :required-field="true"
            :readonly="readonly"
            @ready="syncLoad.ready('status')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="idModalChangeSituation"
      ref="modalChangeSituation"
      class="modalStyle"
      centered
      hide-footer
    >
      <b-row>
        <b-col>
          <span
            class="d-flex justify-content-center px-50 pb-2 my-1 textVerify"
          >
            Deseja concluir o processo de faturamento ou retornar para o envio de entrega?
          </span>
        </b-col>
      </b-row>
      <b-row class="pb-50">
        <b-col
          class="px-5"
        >
          <b-button
            class="w-100"
            variant="primary"
            @click="updateSituationToDone()"
          >
            <span
              class="statusText"
            >
              Concluir Processo de Faturamento
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pb-50">
        <b-col
          class="px-5"
        >
          <b-button
            class="w-100"
            variant="warning"
            @click="updateSituationToIdle()"
          >
            <span
              class="statusText"
            >
              Retornar para envio de Entrega
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pb-50">
        <b-col
          class="px-5"
        >
          <b-button
            class="w-100"
            variant="outline-primary"
            @click="hideModalChangeSituation()"
          >
            <span> Cancelar </span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BCardTitle,
  BCard,
  BButton,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  mdiCheckCircle,
  mdiTruckDeliveryOutline,
  mdiLaunch,
  mdiNoteSearchOutline,
} from '@mdi/js'

import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import CompanySelect from '@/components/company/CompanySelect.vue'
import InvoicingStatusSelect from '@/components/order/InvoicingStatusSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    ValidationProvider,
    CompanySelect,
    InvoicingStatusSelect,
    SvgIcon,
  },
  model: {
    prop: 'invoicingValue',
    event: 'invoicingChange',
  },
  props: {
    invoicingValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.invoicing)

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('status')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      refFormObserver,
      getValidationState,
      syncLoad,
    }
  },
  computed: {
    invoicing: {
      get() {
        return this.invoicingValue
      },
      set(value) {
        this.$emit('invoicingChange', value)
      },
    },
    isInAudit() {
      return this.invoicing.situation === 'audit'
    },
    isDeclined() {
      return this.invoicing.situation === 'declined'
    },
    haveAuditPermission() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.audit.invoicing.can_audit
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
    situationButtonClass() {
      const situationClassMap = {
        done: 'done-btn',
        idle: 'undone-btn',
        released: 'undone-btn',
      }

      return situationClassMap[this.invoicing.situation]
    },
    situationSvgColor() {
      const situationSvgColorMap = {
        done: '#049F14',
        idle: '#777777',
        released: '#777777',
      }

      return situationSvgColorMap[this.invoicing.situation]
    },
    situationSvgPath() {
      const svgPathMap = {
        done: mdiCheckCircle,
        idle: mdiTruckDeliveryOutline,
        released: mdiNoteSearchOutline,
      }

      return svgPathMap[this.invoicing.situation]
    },
    actionText() {
      const situationTextMap = {
        done: 'Faturamento Concluído',
        idle: 'Enviar para Entrega',
        released: 'Concluir Faturamento',
      }

      return situationTextMap[this.invoicing.situation]
    },
    organName() {
      if (this.invoicing.commitment_set.client_set) {
        return this.invoicing.commitment_set.client_set.name
      }
      return ' - '
    },
  },
  watch: {
    'invoicing.company_set': function updateCompany(value) {
      if (value) {
        this.invoicing.company = value.id
      } else {
        this.invoicing.company = ''
        this.invoicing.company_set = ''
      }
    },
    'invoicing.status_set': function updateStatus(value) {
      if (value) {
        this.invoicing.status = value.id
      } else {
        this.invoicing.status = ''
        this.invoicing.status_set = ''
      }
    },
  },
  created() {

  },
  methods: {
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.invoicing.bidding,
        },
      })
      window.open(routeData.href, '_blank')
    },
    changeInvoicingDone() {
      if (this.invoicing.situation === 'done') {
        this.showReopenInvoicingModal()
      } else if (this.invoicing.situation === 'idle') {
        this.showReleaseInvoicingModal()
      } else {
        this.showModalChangeSituation()
      }
    },
    showReleaseInvoicingModal() {
      this.$bvModal
        .msgBoxConfirm('Deseja informar que a nota já foi incluída e deverá sair para entrega?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'd-flex justify-content-center',
          bodyClass: 'text-center',
        })
        .then(value => {
          if (value) {
            this.$emit('updateSituation', 'released')
          }
        })
    },
    showReopenInvoicingModal() {
      this.$bvModal
        .msgBoxConfirm('Deseja reabrir o processo de faturamento?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'd-flex justify-content-center',
          bodyClass: 'text-center',
        })
        .then(value => {
          if (value) {
            this.$emit('updateSituation', 'released')
          }
        })
    },
    showModalChangeSituation() {
      this.$refs.modalChangeSituation.show()
    },
    hideModalChangeSituation() {
      this.$refs.modalChangeSituation.hide()
    },
    updateSituationToDone() {
      this.$emit('updateSituation', 'done')
      this.hideModalChangeSituation()
    },
    updateSituationToIdle() {
      this.$emit('updateSituation', 'idle')
      this.hideModalChangeSituation()
    },
    checkChangeDetections() {
      this.$emit('checkChanges')
    },
  },
  setup() {
    return {
      mdiCheckCircle,
      mdiTruckDeliveryOutline,
      mdiLaunch,
      mdiNoteSearchOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.invoicingtHeader {
  background-color: #F6F6F9;
}

.invoicingInfoTitle {
  color: $primary;
  text-transform: uppercase;
}

.invoicingInfoSubtitleLabel {
  color: $primary;
  font-weight: bold;
  font-size: 1rem;
}

.invoicingInfoSubtitleContent {
  color: #494949;
  font-size: 1rem;
}

#btn-link-invoicing-info {
  border: 0px !important;
}

.status-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .4em;
  max-width: 9em;
}

.right-rounded {
  border-radius: 0 1em 0 0;
}

.statusText {
  color: #ffffff;
  font-size: .9em;
  font-weight: 600;
  margin: 0;
}

.statusSvg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: .4em;
}

.done-btn {
  background-color: #049F14;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475;
  }
}

.undone-btn {
  background-color: #777777;

  &:hover {
    box-shadow: 0 .4rem .6rem #77777775;
  }
}

.audit-btn {
  background-color: #FF7E07;

  &:hover {
    box-shadow: 0 .4rem .6rem #FF7E0775;
  }
}
.releaseModalBtn {
  background-color: #049F14 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475 !important;
  }
}

.declined-btn {
  background-color: #F50F00;

  &:hover {
    box-shadow: 0 .4rem .6rem #F50F0075;
  }
}

.minModalBtnWidth {
  min-width: 8rem !important;
}

.releaseModalBtn {
  background-color: #049F14 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475 !important;
  }
}
.declineModalBtn {
  background-color: #F50F00 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #F50F0075 !important;
  }
}

.textVerify {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}
</style>
