<template>
  <div>
    <b-row
      class="px-1 mb-1"
    >
      <b-col
        class="titleLayout py-1"
      >
        <span>Nota Fiscal</span>
      </b-col>
    </b-row>
    <b-row
      class="px-2"
    >
      <b-col
        cols="12"
        md="4"
      >
        <validation-provider
          #default="validationContext"
          name="note_number"
          :rules="{
            required: false,
            regex:/^([0-9]+)$/
          }"
        >
          <b-form-group
            label="Nº da Nota"
            label-for="note_number"
            :label-class="'formLabel'"
          >
            <b-form-input
              id="note_number"
              v-model="invoicing.note_number"
              :state="readonly ? null : getValidationState(validationContext)"
              :readonly="readonly"
              name="note_number"
              type="number"
              placeholder="Nº da Nota"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="invoicing_date"
          :rules="{
            required: false,
          }"
        >
          <b-form-group
            label="Data de Faturamento"
            label-for="invoicing_date"
            :label-class="'formLabel'"
          >
            <b-form-datepicker
              id="id_invoicing_date"
              v-model="invoicing.invoicing_date"
              :required="false"
              locale="pt-BR"
              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
              size="md"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              label-no-date-selected="Nenhuma data foi selecionada"
              :disabled="readonly"
              selected-variant="primary"
              placeholder="Selecione"
              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
              reset-button
              reset-button-variant="info"
              label-reset-button="Limpar"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="expected_payment_date"
          :rules="{
            required: false,
          }"
        >
          <b-form-group
            label="Previsão de Pagamento"
            label-for="expected_payment_date"
            :label-class="'formLabel'"
          >
            <b-form-datepicker
              id="id_expected_payment_date"
              v-model="invoicing.expected_payment_date"
              :required="false"
              locale="pt-BR"
              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
              size="md"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              label-no-date-selected="Nenhuma data foi selecionada"
              :disabled="readonly"
              selected-variant="primary"
              placeholder="Selecione"
              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
              reset-button
              reset-button-variant="info"
              label-reset-button="Limpar"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="real_pay_date"
          :rules="{
            required: false,
          }"
        >
          <b-form-group
            label="Data de Pagamento"
            label-for="real_pay_date"
            :label-class="'formLabel'"
          >
            <b-form-datepicker
              id="real_pay_date"
              v-model="invoicing.real_pay_date"
              :required="false"
              locale="pt-BR"
              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
              size="md"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              label-no-date-selected="Nenhuma data foi selecionada"
              :disabled="readonly"
              selected-variant="primary"
              placeholder="Selecione"
              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
              reset-button
              reset-button-variant="info"
              label-reset-button="Limpar"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="2"
        class="py-1"
      >
        <div
          v-b-tooltip.hover
          title="Dias de atraso em relação a data da última entrega."
          class="delayedDays"
        >
          <div class="daysTitle">
            <svg-icon
              type="mdi"
              :path="mdiCalendarClock"
              size="22"
              color="primary"
            />
            <h4>Dias em Atraso</h4>
          </div>
          <span>{{ daysDelay }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row
      class="px-1 mb-1 mt-2"
    >
      <b-col
        class="titleLayout py-1"
      >
        <span>Anotações</span>
      </b-col>
    </b-row>
    <b-row
      class="px-2"
    >
      <b-col>
        <validation-provider
          #default="validationContext"
          name="annotation"
        >
          <b-form-textarea
            id="annotation"
            v-model="invoicing.annotation"
            class="annotation"
            :state="readonly ? null : getValidationState(validationContext)"
            trim
            :placeholder="readonly ? '' : 'Anotações'"
            :readonly="readonly"
          />

          <small class="text-danger">
            {{ validationContext.errors[0] }}
          </small>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiCalendarClock,
  mdiMinus,
} from '@mdi/js'

import {
  ValidationProvider,
} from 'vee-validate'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,
    SvgIcon,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'invoicingValue',
    event: 'invoicingChange',
  },
  props: {
    invoicingValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.invoicing)

    const deliveries = []
    const hasDaysDelay = false
    const daysDelay = 0

    return {
      refFormObserver,
      getValidationState,
      deliveries,
      hasDaysDelay,
      daysDelay,
    }
  },
  computed: {
    invoicing: {
      get() {
        return this.invoicingValue
      },
      set(value) {
        this.$emit('invoicingChange', value)
      },
    },
  },
  watch: {
    'invoicing.real_pay_date': function updateRealDate(val) {
      if (val === '') {
        this.invoicing.real_pay_date = null
      } else {
        this.invoicing.real_pay_date = val
        this.calculateDelay()
      }
    },
    'invoicing.invoicing_date': function updatePayDate(val) {
      if (val === '') {
        this.invoicing.invoicing_date = null
      }
    },
    'invoicing.expected_payment_date': function updateRealDate(val) {
      this.invoicing.expected_payment_date = val
      if (val === '') {
        this.invoicing.expected_payment_date = null
      }
    },
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
    calculateDelay() {
      this.hasDaysDelay = false
      let daysQtd = -1
      this.daysDelay = '-'

      if (this.invoicing.delivery_set.invoicing_delivery_date) {
        const deliveryDate = moment(this.invoicing.delivery_set.invoicing_delivery_date)

        if (!Number.isNaN(Date.parse(deliveryDate))) {
          const res = this.invoicing.real_pay_date ? moment(this.invoicing.real_pay_date) : moment()
          daysQtd = res.diff(moment(deliveryDate), 'days')
          this.hasDaysDelay = daysQtd > -1
        }

        if (this.hasDaysDelay) {
          const text = daysQtd < 1 ? 'dia' : 'dias'
          this.daysDelay = `${daysQtd} ${text}`
        } else {
          this.daysDelay = '0 dias'
        }
      }
    },
  },
  setup() {
    return {
      moment,
      mdiCalendarClock,
      mdiMinus,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.form-control.is-valid,
.form-control.is-invalid {
    background-image: none;
    padding-right: inherit;
    border: 1px solid #d8d6de;
}

.annotation {
  min-height: 10.25rem;
}

.titleLayout {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}

.formLabel {
  font-weight: bolder;
}

.delayedDays {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  > .daysTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .4rem;

    > h4 {
      text-transform: uppercase;
      font-weight: bolder;
      color: $primary;
      margin: 0;
    }

    > svg {
      color: $primary;
    }
  }

  > span {
    font-size: 1.4rem;
    font-weight: bolder;
    color: #F50F00;
  }
}
</style>
