<template>
  <div>
    <div
      class="clsCardMessageFooterView"
    >
      <observer @on-change="notifyView">
        <svg-icon
          :id="`idViewEye-${message.id}`"
          type="mdi"
          :path="mdiEyeOutline"
          size="18"
        />
      </observer>
    </div>
    <b-popover
      :target="`idViewEye-${message.id}`"
      triggers="hover"
      placement="bottom"
    >
      <div
        v-if="message.views_by_profile.length == 0"
      >
        <b-row>
          <b-col>
            <span>Nenhuma visualização.</span>
          </b-col>
        </b-row>
      </div>
      <div
        v-else
      >
        <b-row
          v-for="(profile, index) in message.views_by_profile"
          :key="index"
        >
          <b-col>
            <div
              class="d-flex align-items-center"
            >
              <b-avatar size="1.5rem" />
              <span class="d-inline ml-50">{{ profile.get_full_name }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BPopover,
  BAvatar,
} from 'bootstrap-vue'

import {
  mdiEyeOutline,
} from '@mdi/js'

import Observer from 'vue-intersection-observer'
import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BPopover,
    BAvatar,
    SvgIcon,
    Observer,
  },
  props: {
    message: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    notifyView(entry) {
      if (entry.isIntersecting) {
        const profile = syslic.authentication.getProfile()
        const viewer = profile.id

        if (viewer !== this.message.author_set.id) {
          syslic
            .messager
            .visualization
            .notifyVisualization(this.message.id, viewer)
        }
      }
    },
  },
  setup() {
    return {
      mdiEyeOutline,
    }
  },
}
</script>
