var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mentionable",staticStyle:{"position":"relative"}},[_c('div',{ref:"mentionInput"},[_vm._t("default")],2),_c('div',{staticClass:"clsMetionPopover",style:(_vm.caretPosition ? {
      position: 'absolute',
      top: ((_vm.caretPosition.top) + "px"),
      left: ((_vm.caretPosition.left) + "px"),
      height: ((_vm.caretPosition.height) + "px"),
      width: '1px',
    } : {}),attrs:{"id":"mentionPopoverPoint"}}),_c('div',[_c('b-popover',_vm._b({ref:"popper",attrs:{"target":"mentionPopoverPoint","placement":_vm.placement,"show":_vm.isOpen,"triggers":"manual"}},'b-popover',_vm.$attrs,false),[(!_vm.displayedItems.length)?_c('div',[_vm._t("no-result",function(){return [_vm._v(" Não foram encontrados resultados. ")]})],2):_c('div',_vm._l((_vm.displayedItems),function(item,index){return _c('div',{key:index,staticClass:"px-2 py-1",class:{
            'clsMentionSelected': _vm.selectedIndex === index,
          },on:{"mouseover":function($event){_vm.selectedIndex = index},"mousedown":function($event){return _vm.applyMention(index)}}},[_vm._t(("item-" + (_vm.key || _vm.oldKey)),function(){return [_vm._t("item",function(){return [_vm._v(" "+_vm._s(item.label || item.value)+" ")]},{"item":item,"index":index})]},{"item":item,"index":index})],2)}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }