<template>
  <div id="invoicingEditPage">
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <messager
        :module="'invoicing'"
        :thread="invoicing.id"
        :header="invoicing.delivery_set.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row
        class="justify-content-center mb-1"
      >
        <b-col
          cols="12"
          md="11"
        >
          <div>
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    id="bnt-to-commitment"
                    @click="openCommitment()"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiClipboardTextMultipleOutline"
                    />
                    <span class="align-middle ml-50">Acessar Empenho</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    id="bnt-to-delivery"
                    @click="openDelivery()"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiClipboardTextMultiple"
                    />
                    <span class="align-middle ml-50">Acessar Entrega</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="isViewMode && !isInvoicingDone"
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="!isInvoicingDone"
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(invoicing.id)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <validation-observer
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent
            >
              <invoicing-info
                ref="invoicingInfo"
                v-model="invoicing"
                :readonly="isViewMode || isInvoicingDone"
                :can-edit="hasPermissionToUpdate"
                @ready="syncLoad.ready('invoicing-info')"
                @updateSituation="updateInvoicingSituation"
                @checkChanges="hasChanges"
              />
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center mt-2"
      >
        <b-col
          cols="12"
          md="11"
        >
          <div
            class="d-inline-block w-100"
          >
            <b-card no-body>
              <b-tabs
                class="invoicingTab"
                card
              >
                <b-tab
                  class="p-0"
                  :active="activeTab === dataTab.invoicing"
                  @click="selectedTab(dataTab.invoicing)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        class="mr-50 deliveryIconTitle"
                        type="mdi"
                        :path="mdiCash"
                      />
                      <span class="d-inline">Faturamento</span>
                    </b-card-title>
                  </template>
                  <b-card-body
                    class="pt-0 pb-2 px-0"
                  >
                    <invoicing-detail
                      v-model="invoicing"
                      :readonly="isViewMode || isInvoicingDone"
                      :is-loaded="isLoaded"
                      @ready="syncLoad.ready('invoicing-detail')"
                    />
                  </b-card-body>
                </b-tab>
                <b-tab
                  :active="activeTab === dataTab.attach"
                  no-body
                  @click="selectedTab(dataTab.attach)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        type="mdi"
                        :path="mdiPaperclip"
                      />
                      <span class="d-inline">Anexos</span>
                    </b-card-title>
                  </template>
                  <b-card-body
                    class="p-0"
                  >
                    <invoicing-attach
                      v-model="invoicing.file_set"
                      :invoicing-id="invoicing.id"
                      :readonly="isViewMode || isInvoicingDone"
                      @ready="syncLoad.ready('attach')"
                    />
                  </b-card-body>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <invoicing-items-table
            v-model="invoicing"
            @ready="syncLoad.ready('invoicing-items')"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
          class="buttonsSaveCancel d-flex justify-content-end"
        >
          <b-button
            v-if="!isViewMode"
            id="btn-save"
            type="submit"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disable="isFormInvalid"
            @click="onSubmit()"
          >
            Salvar
          </b-button>
          <b-button
            id="itemCancelButton"
            variant="outline-primary"
            class="mb-1 mb-sm-0"
            @click="disableEdition(true)"
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        id="idModalVerifyUnsuccess"
        ref="modalVerifyUnsuccess"
        class="modalStyle"
        centered
        hide-footer
      >
        <b-form
          class="mt-1 pb-2"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-2 layoutErrorModal"
              >
                <svg-icon
                  type="mdi"
                  size="80"
                  :path="mdiAlertCircle"
                />
              </span>
              <span
                class="d-flex justify-content-center px-50 pb-2 my-1 textVerify"
              >
                Falha ao Atualizar a Situação do Faturamento
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="d-flex justify-content-center align-items-centered"
            >
              <ul class="importErrors">
                <li
                  v-for="(error, index) in errorsChangeSituation"
                  :key="index"
                  class="mb-50 textErrors"
                >
                  {{ error }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BModal,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiPackageVariant,
  mdiLaunch,
  mdiPlus,
  mdiTruckOutline,
  mdiCash,
  mdiPaperclip,
  mdiTrashCanOutline,
  mdiClipboardTextMultiple,
  mdiClipboardTextMultipleOutline,
  mdiClipboardTextClockOutline,
  mdiAlertCircle,
  mdiNoteSearchOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import InvoicingAttach from '@/views/order/invoicing/components/InvoicingAttach.vue'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import ChangeDetector from '@/components/utils/changeDetector'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import Messager from '@/components/messager/Messager.vue'
import InvoicingDetail from '@/views//order/invoicing/components/InvoicingDetail.vue'
import InvoicingInfo from '@/views/order/invoicing/components/InvoicingInfo.vue'
import InvoicingItemsTable from '@/views/order/invoicing/components/InvoicingItemsTable.vue'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BButton,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BModal,
    InvoicingAttach,
    SpinLoader,
    Messager,
    InvoicingDetail,
    InvoicingInfo,
    InvoicingItemsTable,
    SvgIcon,
  },
  directives: {
    Ripple,
  },
  filters: {
    truncate,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.invoicing)

    const changeDetector = new ChangeDetector()

    const dataTab = {
      invoicing: 1,
      attach: 2,
    }

    let activeTab = dataTab.invoicing

    let goBackTo = 'invoicing'

    const invoicing = {
      annotation: '',
      commitment: '',
      commitment_set: {
        client_set: '',
        order_set: {
          contract_set: {
            bidding_set: {
              client_set: {
                name: '',
              },
            },
          },
        },
      },
      delivery: '',
      delivery_set: {
        client_set: {
          name: '',
        },
      },
      expected_payment_date: '',
      file_set: '',
      id: '',
      invoicing_date: '',
      note_number: '',
      real_pay_date: '',
      situation: 'idle',
      status: '',
      status_set: '',
      total_nf: '',
      liquid_margin: '',
    }

    const auditPercentage = 0

    const errorsChangeSituation = []
    let isViewMode = false
    let isEditMode = false
    const isFormInvalid = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)) {
      isEditMode = true
    } else {
      isViewMode = true
    }

    invoicing.id = router.currentRoute.params.id
    activeTab = router.currentRoute.params.tab
    goBackTo = router.currentRoute.params.goBackTo

    const isLoaded = false

    const syncLoad = new SyncLoad()
    // syncLoad.addTrigger('messager')
    syncLoad.addTrigger('invoicing')
    syncLoad.addTrigger('invoicing-detail')
    syncLoad.addTrigger('invoicing-info')
    syncLoad.addTrigger('invoicing-items')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      auditPercentage,
      errorsChangeSituation,
      isViewMode,
      isEditMode,
      isFormInvalid,
      refFormObserver,
      getValidationState,
      text_error: '',
      invoicing,
      dataTab,
      activeTab,
      goBackTo,
      isLoaded,
      syncLoad,
      changeDetector,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
    isInvoicingDone() {
      const value = this.invoicing.situation === 'done'

      return value
    },
  },
  watch: {
    'invoicing.company_set': function updateCompany(val) {
      if (val) {
        this.invoicing.company = val.id
      } else {
        this.invoicing.company = ''
      }
    },
    'invoicing.status_set': function updateStatus(val) {
      if (val) {
        this.invoicing.status = val.id
      } else {
        this.invoicing.status = ''
      }
    },
    'invoicing.annotation': function updateAnnotation() {
      if (!this.invoicing.annotation) {
        this.invoicing.annotation = ''
      }
    },
  },
  created() {
    this.fetchData()
    this.changeDetector.bind(this, ['invoicing'])
  },
  methods: {
    fetchData() {
      syslic
        .order
        .invoicing
        .fetchInvoicing(this.invoicing.id)
        .then(response => {
          this.invoicing = response.data
          this.syncLoad.ready('invoicing')
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('invoicing')
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        this.isFormInvalid = !success
        if (!this.isFormInvalid) {
          this.updateInvoicing()
        }
      })
    },
    updateInvoicing() {
      syslic
        .order
        .invoicing
        .updateInvoicing(this.invoicing.id, this.invoicing)
        .then(response => {
          this.invoicing = response.data
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Faturamento atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(false)
        })
        .catch(() => {
          const textError = 'Não foi possível atualizar o faturamento, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Faturamento.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    deleteInvoicing(item) {
      syslic
        .order
        .invoicing
        .deleteInvoicing(item)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Faturamento removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover Faturamento.',
              text: 'Não foi possível remover o faturamento do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o faturamento.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteInvoicing(item)
          }
        })
    },
    enableEdition() {
      this.isViewMode = false
      router.push({
        name: 'invoicing-edit',
        params: {
          id: this.invoicing.id,
          tab: this.activeTab,
          goBackTo: this.goBackTo,
        },
      })
    },
    disableEdition(isGoBack) {
      if (isGoBack) {
        router.push({
          name: 'invoicing',
        })
      } else {
        router.push({
          name: 'invoicing-detail',
          params: {
            id: this.invoicing.id,
            tab: this.activeTab,
            goBackTo: this.goBackTo,
          },
        })
          .catch(() => {})
      }
    },
    selectedTab(value) {
      this.activeTab = value
    },
    updateInvoicingSituation(val) {
      const res = {
        done: 'concluído',
        idle: 'retomado ao estado inicial',
        released: 'enviado para entrega',
      }

      syslic
        .order
        .invoicing
        .updateSituation(this.invoicing.id, val)
        .then(response => {
          this.invoicing.situation = response.data.situation

          this.$swal({
            title: '',
            text: `Faturamento ${res[this.invoicing.situation]} com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 5000,
          })
        })
        .catch(error => {
          this.errorsChangeSituation = []
          if ('data' in error) {
            if (error.data.errors.includes('there are comisssions payed')) {
              this.errorsChangeSituation.push('Existem comissões associadas a este faturamento.')
            }
            if (error.data.errors.includes('note_number is required')) {
              this.errorsChangeSituation.push('O campo Nº da Nota precisa estar preenchido.')
            }
            if (error.data.errors.includes('invoicing_date is required')) {
              this.errorsChangeSituation.push('O campo Data de Faturamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('expected_payment_date is required')) {
              this.errorsChangeSituation.push('O campo Previsão de Pagamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('real_pay_date is required')) {
              this.errorsChangeSituation.push('O campo Data de Pagamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('there is no attachment added')) {
              this.errorsChangeSituation.push('Adicione pelo menos um anexo ao faturamento.')
            }
            if (error.data.errors.includes('delivery of this invoicing is complete')) {
              this.errorsChangeSituation.push('A entrega vinculada a este faturamento está marcada como Concluída, reabra a entrega para poder prosseguir.')
            }
          }
          if (this.errorsChangeSituation.length > 0) {
            this.openModalVerifyUnsuccess()
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao atualizar a situação do Faturamento.',
                text:
                  'Não foi possível atualizar a situação da Faturamento, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          }
        })
        .finally(() => {
          if (this.invoicing.situation === 'done') {
            this.disableEdition(false)
          }
          this.changeDetector.reset()
        })
    },
    openModalVerifyUnsuccess() {
      this.$refs.modalVerifyUnsuccess.show()
    },
    hasChanges() {
      if (this.changeDetector.hasChanges()) {
        return
      }
      this.$refs.invoicingInfo.changeInvoicingDone()
    },
    openCommitment() {
      const routeData = router.resolve({
        name: 'order-commitment-detail',
        params: {
          id: this.invoicing.commitment_set.order,
          idcommitment: this.invoicing.commitment,
        },
      })
      window.open(routeData.href, '_blank')
    },
    openDelivery() {
      const routeData = router.resolve({
        name: 'delivery-detail',
        params: {
          id: this.invoicing.delivery_set.order,
          idDelivery: this.invoicing.delivery,
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPackageVariant,
      mdiLaunch,
      mdiPlus,
      mdiTruckOutline,
      mdiCash,
      mdiPaperclip,
      mdiTrashCanOutline,
      mdiClipboardTextMultiple,
      mdiClipboardTextMultipleOutline,
      mdiClipboardTextClockOutline,
      mdiAlertCircle,
      mdiNoteSearchOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#invoicingEditPage {
  transform: none !important;
}

.disabledEdition .input-group-text{
  background-color: #efefef;
}

#btn-link-bidding-info {
  border: none !important;;
}

.invoicingTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0;
}

.invoicingTab .card-header-tabs {
  margin-bottom: 0;
}

.invoicingTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.invoicingTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.invoicingTab .nav-link.active {
  background-color: #e2edf3;
}

.invoicingTab .nav-link.active h4 {
  color: $primary;
}

.invoicingTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

.textVerify {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}

.textErrors {
  color: #e77000;
  font-weight: 400;
}

.layoutErrorModal {
  svg {
    color: #ff8b1e;
  }
}
</style>
