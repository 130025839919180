<template>
  <div>
    <b-card no-body>
      <b-card-title class="m-0">
        <div class="invoicingtItemsTitle">
          <b-row>
            <b-col
              class="d-flex justify-content-end"
            >
              <div class="mr-2">
                <span class="invoicingInfoTitle">MARGEM LÍQUIDA:</span>
                <span v-if="invoicing.liquid_margin">{{ invoicing.liquid_margin | formatCurrency() }} %</span>
                <span
                  v-else
                  v-b-tooltip.hover
                  title="Não é possível calcular a margem liquida, pois ainda nao existe um frete contratado na entrega relacionada a este faturamento."
                >-</span>
              </div>
              <div class="ml-2">
                <span class="invoicingInfoTitle">TOTAL DA NOTA:</span>
                <span>R$ {{ invoicing.total_nf | formatCurrency() }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-title>
      <div id="idItemListList">
        <b-table
          class="position-relative invoicingTableView"
          thead-class="itemTableHeader"
          tbody-tr-class="itemTableRow"
          primary-key="number"
          show-empty
          responsive
          striped
          hover
          empty-text="Não há itens cadastrados neste faturamento."
          :items="invoicingItems"
          :fields="tableColumns"
          :busy="isLoading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #table-busy>
            <div class="text-center my-3">
              <b-spinner
                variant="info"
                class="align-middle mb-1"
              />
              <br>
              <strong class="pt-2">Carregando ...</strong>
            </div>
          </template>

          <template #cell(field_bidding_number)="field">
            <b-media vertical-align="center">
              <span
                class="font-weight-bold d-block text-nowrap text-color-primary"
              >
                #{{ field.item.biddingNumber }}
              </span>
            </b-media>
          </template>

          <template #cell(field_name_bidding)="field">
            <span class="text-nowrap font-weight-bold">
              {{ field.item.name_bidding }}
            </span>
          </template>

          <template #cell(field_qtd)="field">
            <span class="text-nowrap font-weight-bold">
              {{ field.item.qtd }}
            </span>
          </template>

          <template #cell(field_price)="field">
            <span class="text-nowrap font-weight-bold">
              R$ {{ field.item.price }}
            </span>
          </template>

          <template #cell(field_total_value)="field">
            <span class="text-nowrap font-weight-bold">
              R$ {{ field.item.total_value }}
            </span>
          </template>
        </b-table>
        <div
          class="mx-2 mb-2"
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageSize"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BMedia,
  BCard,
  BTable,
  BSpinner,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'

import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import formatCurrency from '@/components/utils/filters/formatCurrency'

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BMedia,
    BCard,
    BTable,
    BSpinner,
    BPagination,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'invoicingValue',
    event: 'invoicingChange',
  },
  props: {
    invoicingValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
  },
  data() {
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('invoicing-items')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    const isLoading = true

    const invoicingItems = []

    const totalItems = 0
    const currentPage = 1
    const pageSize = 10
    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_bidding_number',
        label: 'Nº ITEM',
        thStyle: { wminWidth: '18rem' },
      },
      {
        key: 'field_name_bidding',
        label: 'ITEM NA LICITAÇÃO',
        thStyle: { minWidth: '18rem' },
      },
      {
        key: 'field_qtd',
        label: 'QUANTIDADE DE PRODUTOS',
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'field_price',
        label: 'VALOR DO ITEM',
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'field_total_value',
        label: 'VALOR TOTAL',
        thStyle: { minWidth: '18rem' },
      },
    ]

    return {
      syncLoad,
      sortBy,
      isSortDirDesc,
      isLoading,
      totalItems,
      invoicingItems,
      currentPage,
      pageSize,
      tableColumns,
    }
  },
  computed: {
    invoicing: {
      get() {
        return this.invoicingValue
      },
      set(value) {
        this.$emit('invoicingChange', value)
      },
    },
  },
  watch: {
    currentPage: function updateCurrentPage() {
      this.fetchItems()
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      const itemsParams = {
        p: this.currentPage,
        page_size: this.pageSize,
      }

      this.isLoading = true

      syslic
        .order
        .invoicing
        .fetchInvoicingItems(this.invoicing.id, itemsParams)
        .then(response => {
          this.createListItems(response.data)

          this.syncLoad.ready('invoicing-items')
          this.isLoading = false
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os itens do faturamentos',
              text: 'Não foi possível ler os itens do faturamentos do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: 'false',
            },
          })
          this.syncLoad.ready('invoicing-items')
          this.isLoading = false
        })
    },
    createListItems(data) {
      this.totalItems = data.total_items
      this.invoicingItems = []
      data.invoicing_items.forEach((element, index) => {
        const jsonBlank = {}
        jsonBlank.biddingNumber = element.item_set.contract_item.number
        jsonBlank.idItem = element.id
        jsonBlank.indexItem = index
        jsonBlank.total_value = this.currencyFormat(element.item_set.contract_item.price * element.quantity)
        jsonBlank.name_bidding = element.item_set.contract_item.name
        jsonBlank.price = this.currencyFormat(element.item_set.contract_item.price)
        jsonBlank.qtd = element.quantity

        this.invoicingItems.push(jsonBlank)
      })
    },
    currencyFormat(value) {
      const res = parseFloat(value).toFixed(2)

      return res.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.invoicingtItemsTitle {
  font-weight: bold;
  padding: 10px 35px;
}

.invoicingInfoTitle {
  color: $primary;
  margin-right: 10px;
  text-transform: uppercase;
}

#idItemListList .itemTableHeader th {
  color: $primary;
  background-color: #E3EDF3 !important;
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#idItemListList .itemListHeader {
  background-color: #F6F6F9
}

#idItemListList .itemTableRow {
  cursor: default;
}
#idItemListList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idItemListList .headerContent {
  color: #464646;
}

#idItemListList .itemIconType {
  color: $primary;
}

.text-color-primary {
  color: $primary;
}
</style>
